/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages

        var header = $("header.banner");
        $(window).scroll(function() {
          if ($(this).scrollTop() >= 112)
          {
            header.addClass("vit-fixed");
          } else {
            header.removeClass("vit-fixed");
          }
        }); 

        ////////////////////////////////////
        /////////Menu Not Logged In/////////
        ////////////////////////////////////
        $("#menu-primary-navigation").smartmenus({ });
        $("#menu-primary-nav-mega-menu").smartmenus({ });
        $("#menu-primary-navigation-parent-child").smartmenus({ });

        function runMenuToggle()
        {
          $("#menu-primary-navigation").slideToggle();
          $("#menu-primary-nav-mega-menu").slideToggle();
          $("#menu-primary-navigation-parent-child").slideToggle();
        }

        $( "#menu-trigger" ).click(function()
        {
          runMenuToggle();
        });


        jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
        jQuery('.quantity').each(function() {
          var spinner = jQuery(this),
          input = spinner.find('input[type="number"]'),
          btnUp = spinner.find('.quantity-up'),
          btnDown = spinner.find('.quantity-down'),
          min = input.attr('min'),
          max = 9000;

          btnUp.click(function() {
            var oldValue = parseFloat(input.val());
            if (oldValue >= max) {
              var newVal = oldValue;
            } else {
              var newVal = oldValue + 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
          });

          btnDown.click(function() {
            var oldValue = parseFloat(input.val());
            if (oldValue <= min) {
              var newVal = oldValue;
            } else {
              var newVal = oldValue - 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
          });

        });

        // $(window).resize(function()
        // {
        //   if ($(window).width() >= 768)
        //   {
        //     $("#menu-primary-navigation").css('display', 'block');
        //   }
        //   else
        //   {
        //     $("#menu-primary-navigation").css('display', 'none');
        //   }
        //
        //   if ($(window).width() >= 992)
        //   {
        //     $("#menu-primary-nav-mega-menu").css('display', 'block');
        //   }
        //   else
        //   {
        //     $("#menu-primary-nav-mega-menu").css('display', 'none');
        //   }
        //
        // });
        ////////////////////////////////////////
        /////////End Menu Not Logged In/////////
        ////////////////////////////////////////

        ////////////////////////////////////
        /////////Menu Logged In/////////
        ////////////////////////////////////
        // SmartMenus init

        // SmartMenus mobile menu toggle button
        $(function() {
          var $mainMenuState = $('#main-menu');
          if ($mainMenuState.length) {
            // animate mobile menu
            $mainMenuState.change(function(e) {
              var $menu = $('#main-menu');
              if (this.checked) {
                $menu.hide().slideDown(250, function() { $menu.css('display', ''); });
              } else {
                $menu.show().slideUp(250, function() { $menu.css('display', ''); });
              }
            });
            // hide mobile menu beforeunload
            $(window).bind('beforeunload unload', function() {
              if ($mainMenuState[0].checked) {
                $mainMenuState[0].click();
              }
            });
          }
        });

        ////////////////////////////////////
        /////////End Menu Logged In/////////
        ////////////////////////////////////

        $(document).ready(function() {
          $(".pirate_forms_three_inputs_wrap").addClass("row");
          $(".contact_submit_wrap").addClass("vit-pirate-submit-button");
        });

        function productTitle(){
          $(".woocommerce .products .product .woocommerce-loop-product__title").height('auto');
          var maxContent = Math.max.apply(Math, $(".woocommerce .products .product .woocommerce-loop-product__title").map(function() { return $(this).height(); }));
          $(".woocommerce .products .product .woocommerce-loop-product__title").each(function() {
            $(this).height(maxContent);
          });
        }

        $(window).resize(function()
        {
          productTitle();
        });

        $(window).load(function() {
          productTitle();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        ////////////////////////////
        ///////Home Feat Align//////
        ////////////////////////////
        function homeFeatAlign(){
          $(".home-feat .feat-prod-title").height('auto');
          $(".home-feat .excerpt").height('auto');

          var maxTitle = Math.max.apply(Math, $(".home-feat .feat-prod-title").map(function() { return $(this).height(); }));
          $(".home-feat .feat-prod-title").each(function() {
            $(this).height(maxTitle);
          });

          var maxExcerpt = Math.max.apply(Math, $(".home-feat .excerpt").map(function() { return $(this).height(); }));
          $(".home-feat .excerpt").each(function() {
            $(this).height(maxExcerpt);
          });
        }
        ////////////////////////////
        /////End Home Feat Align////
        ////////////////////////////

        function alignRotator(){
          $(".home-rotator .flexslider .content").height('auto');
          var maxContent = Math.max.apply(Math, $(".home-rotator .flexslider .content").map(function() { return $(this).height(); }));
          $(".home-rotator .flexslider .content").each(function() {
            $(this).height(maxContent);
          });
        }

        $('.home-rotator .flexslider').flexslider({
          animation: "slide",
          animationLoop: false,
          itemWidth: 260,
          itemMargin: 0,
          minItems: 1,
          maxItems: 3,
        });

        function prodSlideWidth(){
         var browserWidth = $(window).width();
         var setWidth = $(".our-brands-slider .flex-viewport").width()/3;
         if (browserWidth > 767) {
           setWidth = $(".our-brands-slider .flex-viewport").width()/3;
           $("#our-brands-slider li").width(setWidth*3);
           $("#our-brands-slider .brand-item").width(setWidth);
         }else{
           setWidth = $(".our-brand-slider .flex-viewport").width()/1;
           $("#our-brands-slider li").width(setWidth*1);
           $("#our-brands-slider .brand-item").width(setWidth);
         }
       }

       function alignVideoContentContainer(){
        $(".section-resource-videos .video-container").height('auto');
        var maxContent = Math.max.apply(Math, $(".section-resource-videos .video-container").map(function() { return $(this).height(); }));
        $(".section-resource-videos .video-container").each(function() {
          $(this).height(maxContent);
        });
      }

      function alignVideoContentHeading(){
        $(".section-resource-videos .video-container .video-content h2").height('auto');
        var maxContent = Math.max.apply(Math, $(".section-resource-videos .video-container .video-content h2").map(function() { return $(this).height(); }));
        $(".section-resource-videos .video-container .video-content h2").each(function() {
          $(this).height(maxContent);
        });
      }

      function alignVideoContent(){
        $(".section-resource-videos .video-container .video-content p").height('auto');
        var maxContent = Math.max.apply(Math, $(".section-resource-videos .video-container .video-content p").map(function() { return $(this).height(); }));
        $(".section-resource-videos .video-container .video-content p").each(function() {
          $(this).height(maxContent);
        });
      }

      $(window).resize(function(){
       //alignRotator();
       homeFeatAlign();
       prodSlideWidth();
       alignVideoContentContainer();
       alignVideoContentHeading();
       alignVideoContent();
     });

      $(window).load(function() {
       //alignRotator();
       homeFeatAlign();
       prodSlideWidth();
       alignVideoContentContainer();
       alignVideoContentHeading();
       alignVideoContent();
     });

    },
    finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
